.rule-wrap {
    margin: 10% 8%;
}
.wide-rule-wrap {
    margin: 5% 25%;
}

.rule-title {
   text-align: center; 
   font-size: 1.5em;
}

.article {
    margin: 30px 0;
}
.article-title {
   font-size: 1em;
   margin-bottom: 5px;
}

.article-content {
   font-size: 0.9em;
   margin-top: 5px;
}
.article-ol {
    list-style: none;
    padding-left: 0;
    display: table;
}
.article-ol li {
    display: table-row;
    counter-increment: table-ol;
}
.article-ol li::before {
    content: counter(table-ol) ".";
    display: table-cell;
    padding-right: 0.4em;
    text-align: right;
}
.article-ol li::after {
    content: "";
    display: block;
    margin-bottom: 0.5em;
}

.article-sub-list-wrap {
    padding-left: 7%;
    margin-top: 15px;
}
.article-sub-list-wrap p {
    margin: 0 0 10px 0;
}

.article-ul {
    padding-left: 30px;
}
.article-ul li {
    margin-bottom: 10px;
}

.history-wrap {
    margin-top: 50px;
}
.history-title {
    font-size: 0.9em;
    margin-bottom: 0;
}
.history {
    margin: 0;
}
.history li {
    font-size: 0.8em;
    margin: 5px 0;
}