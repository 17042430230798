@charset "utf-8";
@font-face {
    font-family: "skaters";
    src: url('/public/assets/font/Noto_Sans_JP/static/NotoSansJP-Medium.ttf');
}


body {
    font-family: "skaters";
}

/*========= 背景動画設定のCSS ===============*/
/*header設定*/
#header{
    display: flex;
    position: relative;/*h1の中央寄せ配置の起点とするためのrelative*/
    height: 100vh;/*高さを全画面にあわせる*/
} 

#video-area{
    position: fixed;
    z-index: -2;/*最背面に設定*/
    top: 0;
    right:0;
    left:0;
    bottom:0;
    overflow: hidden;
}
#video-area-over {
    position: fixed;
    z-index: -1;/*最背面に設定*/
    top: 0;
    right:0;
    left:0;
    bottom:0;
    background-color: #282C34;
    opacity: 0.8;
}

#video {
    /*天地中央配置*/
    position: absolute;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*縦横幅指定*/
    width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
    height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
    min-height: 100%;
    min-width: 100%;
}
.video {
    /*天地中央配置*/
    position: absolute;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*縦横幅指定*/
    width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
    height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
    min-height: 100%;
    min-width: 100%;
}


/* メインビジュアル */
.mainWrap {
    flex: 1;
}
.contentWrap {
    height: 80%;
    display: flex;
    flex-direction: row;
}
/* メインアプリ説明 */
.titleWrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.headWrap {
    margin-left: auto;
    margin-right: 30px;
}
.mainHead {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logoImg {
    object-fit: cover;
    width: 7vw;
    height: 7vw;
    border-radius: 10px;
}
.mainTitle {
    /*見た目の調整*/
    font-size: 5em;
    color:#E6E9EA;
    text-shadow: 0 0 15px #000;
    margin: 0;
    margin-left: 20px;
}
.subTitle {
    font-size: 1.8em;
    color:#E6E9EA;
    text-shadow: 0 0 15px #000;
    margin: 20% 0 0;
}
/* メイン画像 */
.mainImgWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    position: relative;
}
.mainImg1 {
    object-fit: contain;
    height: 75%;
    position: absolute;
    top: 17%;
    left: 15%;
    z-index: 2;
}
.mainImg2 {
    object-fit: contain;
    height: 75%;
    position: absolute;
    top: 13%;
    left: 5%;
    z-index: 1;
}
/* ダウンロード */
.downloadWrap {
    height: 20%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}
.download-btn {
    object-fit: cover;
    margin: 5px 10px;
}
.download-btn-apple {
    width: 236px;
}
.download-btn-google {
    width: 270px;
    margin-left: 30px;
}
@media screen and (max-width:768px) {
    .contentWrap {
        height: 85%;
        display: flex;
        flex-direction: column;
    }
    .headWrap {
        margin-left: auto;
        margin-right: auto;
    }
    .logoImg {
        object-fit: cover;
        width: 15vw;
        height: 15vw;
        border-radius: 10px;
    }
    .mainTitle {
        /*見た目の調整*/
        font-size: 3.5em;
        margin-left: 10px;
    }
    .subTitle {
        font-size: 1.3em;
        margin: 5% 0 0;
    }

    .mainImgWrap {
        justify-content: center;
        flex: 2;
    }
    .mainImg1 {
        height: 90%;
        position: absolute;
        top: 55%;
        left: 58%;
        z-index: 2;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
    .mainImg2 {
        height: 90%;
        top: 45%;
        left: 42%;
        z-index: 1;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }

    .downloadWrap {
        height: 15%;
        align-items: center;
        justify-content: center;
    }
    .downloadLink {
        flex: 1;
    }
    .download-btn-apple {
        width: 80%;
        margin: 0 auto;
    }
    .download-btn-google {
        width: 90%;
        margin: 0 auto;
    }
}



/*========= メイン領域 ===============*/

#container{
  background:#E6E9EA;
  background-color: transparent;
  text-align: center;
}
.screen-img {
    object-fit: cover;
    width: 60vw;
}

