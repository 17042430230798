.contact-wrap {
    padding: 10%;
}
.contact-wrap-wide {
    padding: 10% 30%;
}

.contact-title {
    width: 100%;
    text-align: center;
    color: #26211E;
    font-size: 1.3em;
    margin-top: 0;
}
.contact-title-wide {
    width: 100%;
    text-align: center;
    color: #26211E;
    font-size: 1.8em;
    margin-top: 0;
}

.contact-app-img {
    object-fit: contain;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    height: auto;
}
.contact-app-img-wide {
    object-fit: contain;
    margin: 0 calc(50% - 25vw);
    width: 50vw;
    height: auto;
}

.contact-form {
    flex: 1;
    width: 100%;
    padding: 10% 0;
}
.contact-input-wrap {
    margin: 30px 0;
}
.contact-input-wrap-last {
    margin-bottom: 10px;
}
.contact-input-label {
    margin: 0;
    font-size: 1em;
}
.contact-input {
    width: 100%;
    font-size: 1em;
    border: 1px solid #d0d0d0;
    background-color: transparent !important;
    padding: 10px;
    margin-top: 15px;
    box-sizing: border-box;
}
.contact-textarea {
    resize: none;
    height: 25vh;
}

::placeholder {
    color: #d0d0d0;
  }
  /* 旧Edge対応 */
  ::-ms-input-placeholder {
    color: #EEE;
  }
  /* IE対応 */
  :-ms-input-placeholder {
    color: #EEE;
  }

.contact-validation {
    font-size: 0.8em;
    color: red;
}

.contact-check-privacy {
    margin-bottom: 20px;
}
.contact-check-privacy-link {
    margin-left: 5px;
    font-size: 0.8em;
}

.contact-attension-wrap {
    margin: 20px 0;
}
.contact-attension {
    color: #C0C0C0;
    font-size: 0.7em;
    margin: 1px 0;
}

.contact-send-wrap {
    text-align: center;
}
.contact-send-btn {
    background-color: #26211E;
    color: rgb(230, 233, 234);
    font-size: 1.1em;
    font-weight: bold;
    border-radius: 100px;
    padding: 5px 30px;
}
.contact-send-btn-disable {
    background-color: #dddddd;
    color: #999999;
}